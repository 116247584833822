<template>
  <div>
    <PageView>
      <h1 v-if="applicationVersion" class="title main-title">
        {{ $t("titles.application-update", { name: applicationName }) }}
      </h1>
      <h1 v-else class="title main-title">{{ $t("titles.application-creation") }}</h1>
      <div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="columns">
            <ValidationProvider
              v-slot="{ errors, valid }"
              class="column is-4"
              name="applicationCreation"
              rules="required"
              vid="applicationCreation"
            >
              <b-field
                :type="{
                  'is-danger': errors && errors.length > 0,
                  'is-success': valid,
                }"
                class="file is-primary"
              >
                <b-upload v-model="applicationConfig.file" accept=".yaml, .zip" class="file-label">
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">{{ $t("applications.chose-config") }}</span>
                  </span>
                  <span v-if="applicationConfig.file" class="file-name">
                    {{ applicationConfig.file.name }}
                  </span>
                </b-upload>
                <sup style="font-size: 1.25em">
                  <b-tooltip :label="$t('applications.help_config')" position="is-right">
                    <a style="color: rgb(0, 100, 100); margin-left: 10px" @click="showHelp">
                      <b-icon icon="question-circle"></b-icon>
                    </a>
                  </b-tooltip>
                </sup>
              </b-field>
            </ValidationProvider>
            <div class="column is-4" style="margin: 5px">
              <b-progress
                v-if="counter < 1"
                :value="counter * 100"
                show-value
                size="is-medium"
                type="is-primary"
              >
                <span v-if="isLoading">
                  <LoadingAnimate :size="'is-small'"></LoadingAnimate>
                </span>
                <span v-else> {{ counter.toFixed(2) * 100 }}% </span>
              </b-progress>
              <b-button
                v-else
                icon-left="vial"
                type="is-light"
                @click="handleSubmit(testApplication)"
              >
                {{ $t("applications.test") }}
              </b-button>
            </div>
            <div class="column is-4">
              <b-tag v-if="btnUpdateConfig" size="is-large" style="margin: 5px" type="is-warning">
                {{
                  $t("applications.app_update_version", {
                    version: applicationConfig.version,
                    name: applicationConfig.name,
                  })
                }}
              </b-tag>
              <b-tag
                v-else-if="applicationVersion"
                size="is-large"
                style="margin: 5px"
                type="is-primary"
              >
                {{
                  $t("applications.app_version", {
                    version: applicationVersion,
                    name: applicationName,
                  })
                }}
              </b-tag>
            </div>
          </div>
          <div class="columns">
            <ValidationProvider
              v-if="applicationConfig.name"
              v-slot="{ errors, valid }"
              class="column"
              name="applicationsName"
              vid="applicationsName"
            >
              <b-field
                :message="errors[0]"
                :type="{
                  'is-danger': errors && errors.length > 0,
                  'is-success': valid,
                }"
                class="input-field"
              >
                <template slot="label">
                  {{ $t("applications.name") }}
                  <span class="mandatory">
                    {{ $t("validation.obligatoire") }}
                  </span>
                </template>

                <b-input
                  v-model="applicationConfig.name"
                  :placeholder="$t('applications.name-placeholder')"
                  maxlength="43"
                >
                </b-input>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="columns">
            <b-field :label="$t('dataTypesRepository.comment')" class="column" expanded>
              <b-input
                v-model="applicationConfig.comment"
                maxlength="200"
                type="textarea"
              ></b-input>
            </b-field>
          </div>
          <div v-if="errorsMessages.length !== 0" class="buttons">
            <ShowErrors
              :errors-messages="errorsMessages"
              :errors-messages-length="errorsMessages.length"
            ></ShowErrors>
          </div>
          <div v-else class="buttons">
            <b-button
              v-if="btnUpdateConfig || applicationVersion || applicationName"
              :disabled="
                applicationConfig.name === '' ||
                applicationConfig.name !== applicationName ||
                disabled
              "
              icon-left="edit"
              type="is-warning"
              @click="handleSubmit(changeConfiguration)"
            >
              {{ $t("applications.change") }}
            </b-button>
            <b-button
              v-else
              :disabled="applicationConfig.name === '' || disabled"
              icon-left="plus"
              type="is-primary"
              @click="handleSubmit(createApplication)"
            >
              {{ $t("applications.create") }}
            </b-button>
          </div>
        </ValidationObserver>
      </div>
    </PageView>
  </div>
</template>

<script>
import PageView from "@/views/common/PageView.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ApplicationConfig } from "@/model/ApplicationConfig";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import ShowErrors from "@/components/application/ShowErrors.vue";
import useBoolean from "@/composable/components/boolean";
import useObject from "@/composable/components/object";
import useArray from "@/composable/components/array";
import useNumber from "@/composable/components/number";
import services from "@/composable/services";
import { i18n } from "@/main";
import app from "@/main";
import { computed, watch } from "vue";

export default {
  name: "ApplicationCreationView",
  props: {
    applicationNames: {
      type: Array,
    },
    applicationName: {
      type: String,
      default: "",
    },
    applicationVersion: {
      type: String,
      default: null,
    },
  },
  components: {
    ShowErrors,
    LoadingAnimate,
    PageView,
    ValidationObserver,
    ValidationProvider,
  },
  setup(props) {
    const { reactiveObject: applicationConfig } = useObject(new ApplicationConfig());
    const { refBoolean: btnUpdateConfig, doChangeBoolean: changeBtnUpdateConfig } = useBoolean();
    const { shallowRefArray: errorsMessages, doChangeArray: changeErrorsMessages } = useArray();
    const { shallowRefArray: infos } = useArray();
    const { shallowRefArray: errors } = useArray();
    const currentUser = services.loginService.getAuthenticatedUser();
    let disabled = useBoolean().refBoolean;
    let counter = useNumber(1).refNumber;
    let isLoading = computed(() => counter.value === 0);

    async function saveApplication(status) {
      counter.value = 0;
      watch(counter, (value) => value);
      let canBeShowAndReturnErrorsMessage = [];
      try {
        let method;
        switch (status) {
          case "create":
            method = (chunk) => {
              return parseChunck(chunk, "create", canBeShowAndReturnErrorsMessage);
            };
            await services.applicationService.createApplication(
              method,
              applicationConfig,
              applicationConfig.comment
            );
            break;
          case "change":
            method = (chunk) => parseChunck(chunk, "change", canBeShowAndReturnErrorsMessage);
            await services.applicationService.changeConfiguration(
              method,
              applicationConfig,
              applicationConfig.comment
            );
            break;
          case "validate":
            method = (chunk) => parseChunck(chunk, "validate", canBeShowAndReturnErrorsMessage);
            await services.applicationService.validateConfiguration(method, applicationConfig);
            break;
        }
      } catch (error) {
        changeErrorsMessages([]);
        checkMessageErrors(error);
      }
    }

    async function createApplication() {
      await saveApplication("create");
    }

    async function changeConfiguration() {
      await saveApplication("change");
    }

    async function testApplication() {
      await saveApplication("validate");
    }

    function showHelp() {
      let routeData = app.$router.resolve("/help");
      window.open(routeData.href, "_blank");
    }

    function testStringWithRegexArray(str, regexArray) {
      return regexArray.some((regexStr) => {
        // Convertir la chaîne en objet RegExp
        const regex = new RegExp(regexStr);
        // Tester la chaîne
        return regex.test(str);
      });
    }

    function parseResultChunck(chunk, caller) {
      const response = chunk.result;
      if ("validate" === caller && response.name) {
        if (
          response.configuration.applicationDescription.name.length < 44 &&
          (currentUser.authorizations.includes(
            response.configuration.applicationDescription.name.toLowerCase()
          ) ||
            testStringWithRegexArray(
              response.configuration.applicationDescription.name.toLowerCase(),
              currentUser.authorizations
            ))
        ) {
          applicationConfig.name = response.configuration.applicationDescription.name.toLowerCase();
          applicationConfig.version = response.configuration.applicationDescription.version.version;
          applicationConfig.comment = response.configuration.applicationDescription.comment;
          if (
            applicationConfig.version.toString() !== props.applicationVersion &&
            applicationConfig.name === props.applicationName
          ) {
            changeBtnUpdateConfig(true);
          }
          if (
            applicationConfig.version.toString() === props.applicationVersion &&
            applicationConfig.name === props.applicationName
          ) {
            disabled.value = true;
            services.alertService.toastError(
              i18n.t("alert.server-error-appli-exist", {
                name: props.applicationName,
                version: props.applicationVersion,
              })
            );
          } else if (props.applicationNames?.includes(applicationConfig.name)) {
            disabled.value = true;
            services.alertService.toastError(
              i18n.t("alert.server-error-appli-exist-on-creation", {
                applicationName: applicationConfig.name,
              })
            );
          } else {
            services.alertService.toastSuccess(i18n.t("alert.application-validate-success"));
          }
        } else {
          disabled.value = true;
          let message =
            response.configuration.applicationDescription.name.toString().length < 48
              ? "badNameWithRight"
              : "invalidLengthName";
          let params =
            message === "invalidLengthName"
              ? {}
              : { name: response.configuration.applicationDescription.name };
          errorsMessages.value.push({
            type: message,
            message: services.errorsService.getErrorsMessages(message, params, "errors-yaml."),
            title: i18n.t("errors-yaml." + message + ".title"),
            params: params,
          });
        }
      } else if ("create" === caller) {
        services.alertService.toastSuccess(i18n.t("alert.application-creation-success"));
        app.$router.push("/applications");
      } else if ("change" === caller) {
        services.alertService.toastSuccess(i18n.t("alert.application-edit-success"));
        app.$router.push("/applications");
      }
    }

    function parseInfoChunck(chunk) {
      infos.value = chunk;
    }

    function parseErrorChunck(chunk, canBeShowAndReturnErrorsMessage) {
      let errorType = chunk.errorType;
      let response = chunk.result;
      if (errorType === "ValidationError") {
        disabled.value = true;
        canBeShowAndReturnErrorsMessage.push({
          type: response.message,
          message: services.errorsService.getErrorsMessages(
            response.message,
            response.params,
            "errors-yaml."
          ),
          title: i18n.t("errors-yaml." + response.message + ".title"),
          params: response.params,
        });
      }
      errorsMessages.value = canBeShowAndReturnErrorsMessage;
      errors.value = chunk;
    }

    function parseProgressChunck(chunk) {
      counter.value = chunk.result;
      watch(counter, (value) => value);
    }

    function parseChunck(chunk, caller, canBeShowAndReturnErrorsMessage) {
      switch (chunk.type) {
        case "REACTIVE_INFO":
          parseInfoChunck(chunk);
          break;
        case "REACTIVE_RESULT":
          parseResultChunck(chunk, caller);
          break;
        case "REACTIVE_PROGRESS":
          parseProgressChunck(chunk);
          break;
        default:
          parseErrorChunck(chunk, canBeShowAndReturnErrorsMessage);
      }
      //console.log("obtening chunk : ", chunk)
    }

    function checkMessageErrors(error) {
      if (error.httpResponseCode === HttpStatusCodes.BAD_REQUEST) {
        errorsMessages.value = services.errorsService.getErrorsMessages(
          error.content.validationCheckResults
        );
      } else {
        services.alertService.toastServerError(error);
      }
    }

    return {
      createApplication,
      changeConfiguration,
      showHelp,
      testApplication,
      applicationConfig,
      btnUpdateConfig,
      errorsMessages,
      isLoading,
      counter,
      errors,
      disabled,
      currentUser,
    };
  },
};
</script>

<style lang="scss">
.btnErrors {
  animation-duration: 0.8s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}

@keyframes clignoter {
  50% {
    background-color: rgb(166, 0, 0);
    color: #dbdbdb;
  }
}
</style>
