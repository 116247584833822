<script>
export default {
  name: "TitleAndDescription",
  props: {
    application: Object,
    localName: String,
    localDescription: String,
    localTitle: String,
  },
};
</script>

<template>
  <h1 class="title main-title">
    <slot name="label" v-bind:option="application">
      {{ localTitle || localName }}
    </slot>
    <slot name="description" v-bind:option="application" v-if="localDescription">
      <sup style="font-size: 1.5em">
        <b-tooltip
          :label="$t('applications.description', { localDescription: localDescription })"
          position="is-top"
        >
          <b-icon icon="info-circle" type="is-dark"></b-icon>
        </b-tooltip>
      </sup>
    </slot>
  </h1>
</template>

<style scoped lang="scss">
.icon {
  vertical-align: text-top;
}
</style>
