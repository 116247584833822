<template>
  <PageView :application="application" class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />
    <TitleAndDescription
      :application="application"
      :localName="application.localName || application.title"
      :local-title="
        $t('titles.requestAuthorization', {
          applicationName: application.localName || application.title,
        })
      "
    />
    <div>
      <b-table
        :data="listRightsRequests"
        :striped="true"
        default-sort="row.name"
        default-sort-direction="asc"
        height="100%"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        sortable
      >
        <b-table-column :searchable="true" field="name" label="Nom du demandeur" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <p v-if="props.row.user">
              {{ getUserLabel(props.row.user) }}
            </p>
          </template>
        </b-table-column>
        <b-table-column :searchable="true" field="email" label="E-mail du demandeur" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <p v-if="props.row.user">
              {{ getUserEmail(props.row.user) }}
            </p>
          </template>
        </b-table-column>
        <b-table-column :searchable="true" field="comment" label="Commentaire" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <a @click="manageRequest(props.row.id)" class="is-flex is-align-content-center">
              <b-icon icon="pen-square" size="is-small"></b-icon>
              <span class="with-padding">{{ props.row.comment }}</span>
            </a>
          </template>
        </b-table-column>
        <b-table-column :searchable="true" field="createDate" label="Date de création" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            {{ formatDate(props.row.createDate) }}
          </template>
        </b-table-column>
        <b-table-column :searchable="true" field="updateDate" label="Date de création" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            {{ formatDate(props.row.updateDate) }}
          </template>
        </b-table-column>
        <!--
        <b-table-column :searchable="true" field="number request" label="N° de la demande" sortable>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              icon="search"
            />
          </template>
          <template v-slot="props">
            <a @click="manageRequest(props.row.id)">
              {{ props.row.id.split("-").pop() }}
            </a>
          </template>
        </b-table-column>
-->
        <b-table-column :searchable="true" field="setted" label="Statut" sortable>
          <template #searchable>
            <b-select
              v-model="filterState"
              :placeholder="filterStates[filterState].label"
              @input="isVisibleRequest"
            >
              <option v-for="(option, id) in filterStates" :key="id" :value="id">
                {{ option.label }}
              </option>
            </b-select>
          </template>
          <template v-slot="props">
            <b-icon v-if="props.row.setted" icon="circle" type="is-success"></b-icon>
            <b-icon v-else icon="circle" type="is-danger"></b-icon>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </PageView>
</template>

<script>
import moment from "moment";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import services from "@/composable/services";
import app, { i18n } from "@/main";
import useObject from "@/composable/components/object";
import { onMounted, ref, watch } from "vue";
import useArray from "@/composable/components/array";
import TitleAndDescription from "@/components/common/TitleAndDescription.vue";

export default {
  name: "RequestAuthorizationManagementView",
  components: {
    TitleAndDescription,
    PageView,
    SubMenu,
  },
  props: {
    dataTypeId: String,
    applicationName: String,
  },
  setup(props) {
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { shallowRefArray: listAllUsers, doChangeArray: changeListAllUsers } = useArray();
    const { reactiveObject: rightsRequests, doChangeObject: changeRightsRequests } = useObject();
    const { shallowRefArray: listRightsRequests, doChangeArray: changeListRightsRequests } =
      useArray();
    let canManageRights = false;
    let filterState = ref(2);
    watch(
      () => filterState,
      (value) => {
        if (value.value === 0) {
          changeListRightsRequests(
            rightsRequests.rightsRequests.filter((request) => request.setted === false)
          );
        } else if (value.value === 1) {
          changeListRightsRequests(
            rightsRequests.rightsRequests.filter((request) => request.setted === true)
          );
        } else {
          changeListRightsRequests(rightsRequests.rightsRequests);
        }
      }
    );
    let filterStates = [
      {
        type: "open",
        label: i18n.t("dataTypeAuthorizations.filterScope.open"),
      },
      {
        type: "close",
        label: i18n.t("dataTypeAuthorizations.filterScope.close"),
      },
      {
        type: "all",
        label: i18n.t("dataTypeAuthorizations.filterScope.all"),
      },
    ];

    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n.t("requestAuthorization.request").toLowerCase(),
          () => {},
          () => app.$router.push("/applications/" + props.applicationName)
        ),
      ]);
    });

    function getDate(value) {
      return moment(value).format("DD/MM/YYYY");
    }

    function getUserEmail(id) {
      let userEmail = "Email non trouvé";
      if (JSON.parse(localStorage.getItem("authenticatedUser")).id === id) {
        userEmail = JSON.parse(localStorage.getItem("authenticatedUser")).email;
      } else {
        listAllUsers.value.forEach((user) => {
          if (user.id === id) {
            userEmail = user.email;
          }
        });
      }
      return userEmail;
    }

    function getUserLabel(id) {
      let userLabel = "Label non trouvé";
      if (JSON.parse(localStorage.getItem("authenticatedUser")).id === id) {
        userLabel = JSON.parse(localStorage.getItem("authenticatedUser")).login;
      } else {
        listAllUsers.value.forEach((user) => {
          if (user.id === id) {
            userLabel = user.label;
          }
        });
      }
      return userLabel;
    }

    async function init() {
      try {
        let getApplication = await services.applicationService.getApplication(
          props.applicationName,
          ["DATATYPE", "REFERENCETYPE", "CONFIGURATION", "RIGHTSREQUEST"]
        );
        changeApplication(
          services.internationalisationService.mergeInternationalization(getApplication)
        );
        canManageRights =
          application.isAdministrator ||
          Object.values(application.authorizationsDatatypesRights || []).some(
            (rights) => rights.ADMIN
          );
        let localRightsRequests = await services.requestRightsService.getRightsRequests(
          props.applicationName
        );
        for (const request of localRightsRequests.rightsRequests) {
          request.children = [{}];
          request.label = request.id;
        }
        let localeListAllUsers = await services.authorizationService.getAuthorizationGrantableInfos(
          props.applicationName
        );
        changeListAllUsers(localeListAllUsers.users.filter((user) => user.label !== "_public_"));
        if (localRightsRequests.rightsRequests) {
          changeListRightsRequests(localRightsRequests.rightsRequests);
        }
        changeRightsRequests(localRightsRequests);
      } catch (error) {
        services.alertService.toastServerError(error);
      }
    }

    function isVisibleRequest(setted) {
      let filterRiquest = rightsRequests;
      if (setted === 0) {
        filterRiquest =
          rightsRequests.rightsRequests.filter((request) => request.setted === false) || [];
        changeListRightsRequests(filterRiquest);
      } else if (setted === 1) {
        filterRiquest =
          rightsRequests.rightsRequests.filter((request) => request.setted === true) || [];
        changeListRightsRequests(filterRiquest);
      } else {
        changeListRightsRequests(rightsRequests.rightsRequests);
      }
    }

    function manageRequest(id) {
      app.$router.push(`/applications/${props.applicationName}/authorizationsRequest/${id}`);
    }
    function formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    }

    return {
      formatDate,
      manageRequest,
      isVisibleRequest,
      getUserEmail,
      getUserLabel,
      getDate,
      subMenuPaths,
      filterStates,
      filterState,
      listAllUsers,
      canManageRights,
      rightsRequests,
      application,
      listRightsRequests,
    };
  },
};
</script>
<style lang="scss">
td {
  padding: 6px;

  .columns {
    margin: 0;

    .column.is-half {
      padding: 6px;
    }
  }
}

.listAuthorization {
  border: solid #dbdbdb;
  border-width: 0 0 1px;
  margin: 0 10px 0 10px;
  padding: 15px;
}

.listAuthorization:nth-child(odd) {
  background-color: #f5f5f5;
}
.with-padding {
  padding-left: 1em;
}
</style>
