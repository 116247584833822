<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />
    <TitleAndDescription
      :application="application"
      :localDescription="currentAuthorization.description"
      :localName="application.localName || application.title"
      :local-title="
        authorizationId === 'new'
          ? $t('titles.data-type-new-authorization')
          : currentAuthorization.name
      "
    />
    <ValidationObserver ref="observer">
      <div class="columns">
        <ValidationProvider
          v-slot="{ errors, valid }"
          class="column is-5"
          name="name"
          rules="required"
          vid="name"
        >
          <b-field
            :label="$t('dataTypeAuthorizations.name-authorization')"
            :message="errors[0]"
            :type="{
              'is-danger': errors && errors.length > 0,
              'is-success': valid,
            }"
            class="column mb-4"
          >
            <b-input
              v-model="currentAuthorization.name"
              :placeholder="$t('dataTypeAuthorizations.name-authorization-placeholder')"
            />
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, valid }"
          class="column is-5"
          name="name"
          rules="required"
          vid="name"
        >
          <b-field
            :label="$t('applications.comment')"
            :message="errors[0]"
            :type="{
              'is-danger': errors && errors.length > 0,
              'is-success': valid,
            }"
            class="column mb-4"
          >
            <b-input
              v-model="currentAuthorization.description"
              :placeholder="$t('dataTypeAuthorizations.comment-authorization-placeholder')"
              maxlength="200"
              type="textarea"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>
      <LoadingAnimate v-if="false" :size="'is-large'"></LoadingAnimate>
    </ValidationObserver>
    <AuthorizationTableForDatatype
      :application="application"
      :current-authorization="currentAuthorization"
      :list-column-name="listColumnName"
      :authorization-id="authorizationId"
      :authorizations="authorizations"
      :datatypes="datatypes"
      :references="references"
      :has-dependencies="hasDependencies"
      :initialized="initialized"
      :application-name="applicationName"
      :references-scopes="referencesScopes"
      @update:authorization="updateAuthorization"
    >
    </AuthorizationTableForDatatype>
    <div class="buttons">
      <b-button
        v-if="currentAuthorization.name && currentAuthorization.description"
        icon-left="plus"
        style="margin-bottom: 0.625em; margin-top: 1.25em"
        type="is-dark"
        @click="createOrUpdateAuthorization"
      >
        {{
          authorizations.uuid
            ? $t("dataTypeAuthorizations.modify")
            : $t("dataTypeAuthorizations.create")
        }}
      </b-button>
      <b-notification v-else aria-close-label="Close notification" type="is-info is-light">
        {{ $t("dataTypeAuthorizations.notification-missing-info") }}
      </b-notification>
    </div>
  </PageView>
</template>

<script>
import PageView from "../common/PageView.vue";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { onMounted, provide, ref, computed, onUpdated } from "vue";
import useArray from "@/composable/components/array";
import app, { i18n } from "@/main";
import services from "@/composable/services";
import { dataLoader } from "@/composable/data/dataLoader";
import AuthorizationTableForDatatype from "@/components/common/AuthorizationTableForDatatype.vue";
import TitleAndDescription from "@/components/common/TitleAndDescription.vue";

export default {
  name: "DataTypeAuthorizationInfoView",
  emits: ["update:infoAuth"],
  components: {
    TitleAndDescription,
    AuthorizationTableForDatatype,
    PageView,
    SubMenu,
    ValidationObserver,
    ValidationProvider,
    LoadingAnimate,
  },
  props: {
    applicationName: String,
    authorizationId: {
      type: String,
      default: "new",
    },
    users: Array,
  },
  setup(props) {
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const application = ref({});
    const datas = ref({});
    const datatypes = ref({ withScope: {}, withoutScope: {} });
    const references = ref({ withScope: {}, withoutScope: {} });
    const referencesScopes = ref({});
    const initialized = ref(false);
    const configuration = ref({});
    const selectedUsers = ref([]);
    const authorizations = ref({});
    const hasPublicAuthorizations = ref(false);
    const isLoading = ref(true);
    const listColumnName = ref({});
    const currentAuthorization = ref({});
    const dependencies = ref({});
    const buildDependencies = (hierarchicalNodes) => {
      const dependenciesObject = {};

      // Initialiser l'objet avec tous les datatypes
      hierarchicalNodes.forEach((node) => {
        dependenciesObject[node.nodeName] = {
          dependencies: [],
          dependents: [],
        };
      });

      // Remplir les dépendances et les dépendants
      hierarchicalNodes.forEach((node) => {
        // Ajouter les dépendances
        if (node.depends && node.depends.length > 0) {
          dependenciesObject[node.nodeName].dependencies = node.depends;

          // Ajouter ce datatype comme dépendant pour chacune de ses dépendances
          node.depends.forEach((dep) => {
            if (dependenciesObject[dep]) {
              dependenciesObject[dep].dependents.push(node.nodeName);
            }
          });
        }

        // Gérer la relation parent-enfant
        if (node.parent) {
          dependenciesObject[node.nodeName].dependencies.push(node.parent);
          if (dependenciesObject[node.parent]) {
            dependenciesObject[node.parent].dependents.push(node.nodeName);
          }
        }

        // Gérer les enfants
        if (node.children && node.children.length > 0) {
          node.children.forEach((childName) => {
            if (dependenciesObject[childName]) {
              dependenciesObject[childName].dependencies.push(node.nodeName);
              dependenciesObject[node.nodeName].dependents.push(childName);
            }
          });
        }
      });

      return dependenciesObject;
    };
    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          i18n.t("menu.accueil").toLowerCase(),
          () => app.$router.push(`/applications/${props.applicationName}`),
          () => app.$router.push("/applications")
        ),
        new SubMenuPath(
          i18n.t(`dataTypeAuthorizations.sub-menu-data-type-authorizations`),
          () => {
            app.$router.push(`/applications/${props.applicationName}/authorizations`);
          },
          () => app.$router.push(`/applications/${props.applicationName}`)
        ),
        new SubMenuPath(
          i18n.t(`dataTypeAuthorizations.sub-menu-new-authorization`),
          () => {},
          () => {
            app.$router.push(`/applications/${props.applicationName}/authorizations`);
          }
        ),
      ]);
    });

    onUpdated(() => {
      if (props.authorizationId !== "new" && currentAuthorization.value.users) {
        selectedUsers.value = currentAuthorization.value.users;
      } else if (props.authorizationId !== "new" && props.users) {
        selectedUsers.value = props.users;
      }
    });

    async function init() {
      try {
        let getApplication = await services.applicationService.getApplication(
          props.applicationName,
          ["CONFIGURATION", "DATATYPE"]
        );
        application.value =
          services.internationalisationService.mergeInternationalization(getApplication);
        datas.value = Object.keys(application.value.data).reduce((acc, data) => {
          acc[data] = {
            id: data,
            name:
              services.internationalisationService.localeReferenceNames(data, application.value) ||
              data,
          };
          return acc;
        }, {});
        datatypes.value = {
          withScope: Object.keys(application.value.dataTypes)
            .filter((name) => application.value.configuration.dataDescription[name].authorization)
            .reduce((acc, dataType) => {
              acc[dataType] = {
                id: dataType,
                name:
                  services.internationalisationService.localeReferenceNames(
                    dataType,
                    application.value
                  ) || dataType,
              };
              return acc;
            }, {}),
          withoutScope: Object.keys(application.value.dataTypes)
            .filter(
              (name) => application.value.configuration.dataDescription[name].authorization === null
            )
            .reduce((acc, dataType) => {
              acc[dataType] = {
                id: dataType,
                name:
                  services.internationalisationService.localeReferenceNames(
                    dataType,
                    application.value
                  ) || dataType,
              };
              return acc;
            }, {}),
        };
        references.value = {
          withScope: Object.keys(application.value.references)
            .filter((name) => application.value.configuration.dataDescription[name].authorization)
            .reduce((acc, reference) => {
              acc[reference] = {
                id: reference,
                name:
                  services.internationalisationService.localeReferenceNames(
                    reference,
                    application.value
                  ) || reference,
              };
              return acc;
            }, {}),
          withoutScope: Object.keys(application.value.references)
            .filter(
              (name) => application.value.configuration.dataDescription[name].authorization === null
            )
            .reduce((acc, reference) => {
              acc[reference] = {
                id: reference,
                name:
                  services.internationalisationService.localeReferenceNames(
                    reference,
                    application.value
                  ) || reference,
              };
              return acc;
            }, {}),
        };
        configuration.value = application.value.configuration;
        dependencies.value = buildDependencies(configuration.value.hierarchicalNodes);
        if (
          props.authorizationId === "public" ||
          window.location.href.split("/").includes("public")
        ) {
          hasPublicAuthorizations.value = true;
          selectedUsers.value = [
            {
              id: "9032ffe5-bfc1-453d-814e-287cd678484a",
              label: "_public_",
            },
          ];
        } else if (props.authorizationId === "new") {
          selectedUsers.value = [JSON.parse(localStorage.authenticatedUser)];
        }
        for (let data of Object.keys(configuration.value.dataDescription)) {
          if (configuration.value.dataDescription[data].authorization) {
            authorizations.value[data] = configuration.value.dataDescription[data].authorization;
          }
        }
        listColumnName.value["withoutScope"] = {
          delete: {
            display: true,
            title: "delete",
            withPeriods: false,
            withDataGroups: false,
            forPublic: false,
            forRequest: false,
            internationalizationName: {
              fr: "Suppression",
              en: "Deletion",
            },
          },
          depot: {
            display: true,
            title: "depot",
            withPeriods: false,
            withDataGroups: false,
            forPublic: false,
            forRequest: false,
            internationalizationName: {
              fr: "Dépôt",
              en: "Deposit",
            },
          },
          extraction: {
            display: true,
            title: "extraction",
            withPeriods: true,
            withDataGroups: true,
            forPublic: true,
            forRequest: true,
            internationalizationName: {
              fr: "Extraction",
              en: "Extraction",
            },
          },
        };
        listColumnName.value["withScope"] = {
          delete: {
            display: true,
            title: "delete",
            withPeriods: false,
            withDataGroups: false,
            forPublic: false,
            forRequest: false,
            internationalizationName: {
              fr: "Suppression",
              en: "Deletion",
            },
          },
          depot: {
            display: true,
            title: "depot",
            withPeriods: false,
            withDataGroups: false,
            forPublic: false,
            forRequest: false,
            internationalizationName: {
              fr: "Dépôt",
              en: "Deposit",
            },
          },
          publication: {
            display: true,
            title: "publication",
            withPeriods: false,
            withDataGroups: false,
            forPublic: false,
            forRequest: false,
            internationalizationName: {
              fr: "Publication",
              en: "Publication",
            },
          },
          extraction: {
            display: true,
            title: "extraction",
            withPeriods: true,
            withDataGroups: true,
            forPublic: true,
            forRequest: true,
            internationalizationName: {
              fr: "Extraction",
              en: "Extraction",
            },
          },
        };
        if (hasPublicAuthorizations.value) {
          delete listColumnName.value["withoutScope"].delete;
          delete listColumnName.value["withScope"].delete;
          delete listColumnName.value["withoutScope"].depot;
          delete listColumnName.value["withScope"].depot;
          delete listColumnName.value["withScope"].publication;
        }
      } catch (error) {
        services.alertService.toastServerError(error);
      } finally {
        isLoading.value = false;
      }

      let listAllUsers = await services.authorizationService.getAuthorizationGrantableInfos(
        props.applicationName
      );
      let scopes = listAllUsers.referenceScopes;
      referencesScopes.value = Object.keys(scopes || {}).reduce((acc, dataName) => {
        if (scopes[dataName].length) {
          acc[dataName] = scopes[dataName];
        }
        return acc;
      }, {});
      if (props.authorizationId !== "new") {
        services.authorizationService
          .getAdminAuthorizationsForOpenAdom(props.applicationName, props.authorizationId)
          .then((authorization) => {
            currentAuthorization.value = authorization;
            initialized.value = true;
          });
      } else {
        currentAuthorization.value = {};
        initialized.value = true;
      }
    }

    function createOrUpdateAuthorization() {
      try {
        const authorizations = buildAuthorization();
        services.authorizationService.createAuthorization(props.applicationName, authorizations);
        app.$router.push(`/applications/${props.applicationName}/authorizations`);
      } catch (e) {
        console.log(e);
      }
    }

    function updateAuthorization(authorizations) {
      let current = { ...currentAuthorization.value };
      current.authorizations = authorizations;
      currentAuthorization.value = current;
    }

    const hasDependencies = computed(() => {
      const result = new Set();
      const authorizations = currentAuthorization.value.authorizations;
      const hierarchicalNodes = configuration.value.hierarchicalNodes;

      for (const [datatype, auth] of Object.entries(authorizations || {})) {
        if (auth.operationTypes && auth.operationTypes.length > 0) {
          // Trouver le nœud correspondant dans la configuration
          const node = hierarchicalNodes.find((n) => n.nodeName === datatype);
          if (node && node.depends && node.depends.length > 0) {
            // Ajouter toutes les dépendances du datatype
            node.depends.forEach((dep) => result.add(dep));
          }
        }
      }

      return Array.from(result);
    });
    const buildAuthorization = () => {
      const authorizationForAll = {};
      const authorizationsWithRestriction = {};
      const authorization = { ...currentAuthorization.value.authorizations };
      for (const [datatype, auth] of Object.entries(authorization)) {
        // Ignorer les datatypes sans opérationTypes ou avec seulement 'extraction' s'ils sont dans hasDependencies
        if (
          !auth.operationTypes ||
          (JSON.stringify(auth.operationTypes) === '["extraction"]' &&
            hasDependencies?.value &&
            hasDependencies.value.includes(datatype))
        ) {
          continue;
        } else {
          auth.operationTypes = [...auth.operationTypes];
        }
        if (!auth.timescope && !auth.requiredAuthorizations) {
          authorizationForAll[datatype] = auth.operationTypes;
          continue;
        }

        // Vérifier si le timeScope est infini
        const isInfiniteTimeScope =
          auth.fromDay !== undefined &&
          auth.fromDay[0] === -999999999 &&
          auth.toDay !== undefined &&
          auth.toDay[0] === 999999999;

        // Vérifier si requiredAuthorizations est vide ou égal à [""]
        const hasNoRequiredAuths =
          !auth.requiredAuthorizations ||
          Object.values(auth.requiredAuthorizations).every(
            (arr) => arr.length === 0 || (arr.length === 1 && arr[0] === "")
          );

        if (isInfiniteTimeScope && hasNoRequiredAuths) {
          if (!auth.requiredAuthorizations) {
            authorizationForAll[datatype] = new Set(auth.operationTypes);
          }
        } else {
          authorizationsWithRestriction[datatype] = {
            operationTypes: auth.operationTypes,
            requiredAuthorizations: auth.requiredAuthorizations,
            timeScope: {},
          };

          // Ajouter fromDay seulement s'il n'est pas infini
          if (auth.fromDay && auth?.fromDay[0] !== -999999999) {
            authorizationsWithRestriction[datatype].timeScope.fromDay = `${
              auth.fromDay[0]
            }-${auth.fromDay[1].toString().padStart(2, "0")}-${auth.fromDay[2]
              .toString()
              .padStart(2, "0")}`;
          }

          // Ajouter toDay seulement s'il n'est pas infini
          if (auth.toDay && auth?.toDay?.[0] !== 999999999) {
            authorizationsWithRestriction[datatype].timeScope.toDay = `${
              auth.toDay[0]
            }-${auth.toDay[1].toString().padStart(2, "0")}-${auth.toDay[2]
              .toString()
              .padStart(2, "0")}`;
          }

          // Si timeScope est vide, le supprimer complètement
          if (Object.keys(authorizationsWithRestriction[datatype].timeScope).length === 0) {
            delete authorizationsWithRestriction[datatype].timeScope;
          }
        }
      }

      return {
        uuid: props.authorizationId === "new" ? null : props.authorizationId,
        name: currentAuthorization.value.name,
        description: currentAuthorization.value.description,
        usersId: (selectedUsers.value || []).map((user) => user.id),
        authorizationForAll,
        authorizationsWithRestriction,
      };
    };

    return {
      createOrUpdateAuthorization,
      updateAuthorization,
      application,
      datatypes,
      configuration,
      authorizations,
      selectedUsers,
      isLoading,
      references,
      datas,
      subMenuPaths,
      listColumnName,
      referencesScopes,
      currentAuthorization,
      initialized,
      hasDependencies,
      buildAuthorization,
      hasPublicAuthorizations,
    };
  },
};
</script>

<style lang="scss">
.DataTypeAuthorizationInfoView-periods-container {
  .field-body .field.has-addons {
    display: flex;
    flex-direction: column;
  }
}

.DataTypeAuthorizationInfoView-radio-field {
  height: 40px;

  &.b-radio {
    .control-label {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.DataTypeAuthorizationInfoView-radio-label {
  width: 200px;
}

.collapse-content .card-content .content .CollapsibleTree-header .CollapsibleTree-buttons {
  visibility: hidden;
  display: none;
}

.leaf label {
  font-weight: lighter;
  font-style: italic;
  color: #2c3e50;
}

.folder label {
  font-weight: bolder;
  color: $dark;
}

.rows .card-content .row.label .columns .column {
  padding: 0 0 0 10px;
  border-bottom: 2px solid;
  border-color: $dark;
  margin-bottom: 12px;
}

ul li.card-content {
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: $dark;
}
</style>
