<template>
  <div>
    <div
      :class="`columns CollapsibleTree-header ${
        option.children && option.children.length !== 0 ? 'clickable' : ''
      } ${option.children && option.children.length !== 0 && displayChildren ? '' : 'mb-1'}`"
      :style="`margin:0px;`"
      @click="displayChildren = !displayChildren"
      @keyup.enter="displayChildren = !displayChildren"
    >
      <div class="CollapsibleTree-header-infos column is-two-thirds">
        <div
          :style="`transform:translate(${level * 50}px);`"
          class="CollapsibleTree-header-infos column is-narrow"
        >
          <slot name="secondaryMenu" v-bind:onClickLabelCb="onClickLabelCb" v-bind:option="option">
            <div
              :class="onClickLabelCb ? 'link' : ''"
              tabindex="0"
              @click="(event) => onClickLabelCb && onClickLabelCb(event, option.label)"
              @keyup.enter="(event) => onClickLabelCb && onClickLabelCb(event, option.label)"
            >
              <!-- TODO Lucile : btn ouverture sidePanel à redéfinir ce qu'on met dedant puis refaire-->
              <b-tooltip
                v-if="false"
                :label="$t('dataTypesManagement.tooltip_show_secondary_menu')"
                type="is-primary is-light"
              >
                <b-button
                  class="is-small"
                  outlined
                  style="margin: 10px"
                  tabindex="0"
                  type="is-primary"
                >
                  <b-icon icon="bars"></b-icon>
                </b-button>
              </b-tooltip>
            </div>
          </slot>
          <slot name="openSubMenu" v-bind:displayChildren="displayChildren">
            <FontAwesomeIcon
              v-if="option.children && option.children.length !== 0"
              :icon="displayChildren ? 'caret-down' : 'caret-right'"
              aria-hidden="false"
              class="clickable mr-3"
              tabindex="0"
            />
          </slot>
          <slot name="label" v-bind:option="option">
            <p>{{ option.localName || option.dataNameLocal || option.label }}</p>
          </slot>
          <slot name="description" v-bind:option="option">
            <sup style="font-size: 1.25em">
              <b-tooltip
                :label="
                  $t('applications.description', { localDescription: option.dataDescriptionLocal })
                "
                position="is-top"
              >
                <b-icon icon="info-circle" type="is-dark"></b-icon>
              </b-tooltip>
            </sup>
          </slot>
          <slot name="tags" v-bind:option="option" v-bind:tags="tags">
            <TagsInfos
              v-if="option && Object.keys(tags || {}).length"
              :info-parent="option"
              :tags-column="tags"
              :tags-menu="true"
            ></TagsInfos>
          </slot>
        </div>
        <LoadingAnimate v-if="isLoading" :size="'is-small'"></LoadingAnimate>
        <div
          v-else
          :style="`transform:translate(${level * 50}px);`"
          class="CollapsibleTree-header-infos column is-narrow"
        >
          <slot
            name="synthesisDetail"
            v-bind:lineCount="lineCount"
            v-bind:onClickLabelSynthesisDetailCb="onClickLabelSynthesisDetailCb"
            v-bind:option="option"
            v-bind:refFile="refFile"
          >
            <div
              v-if="option.synthesisMinMax"
              :class="
                option.synthesisMinMax && onClickLabelSynthesisDetailCb
                  ? 'tile synthesis-details link column is-four-fifths'
                  : 'tile synthesis-details column is-full'
              "
              @click="
                (event) =>
                  option.synthesisMinMax &&
                  onClickLabelSynthesisDetailCb &&
                  onClickLabelSynthesisDetailCb(event, option)
              "
            >
              <span class="synthesis-infos has-text-info-dark column is-full">
                <b-field v-show="false">
                  {{
                    new Date(option.synthesisMinMax[0]).toLocaleDateString("fr") +
                    " - " +
                    new Date(option.synthesisMinMax[1]).toLocaleDateString("fr")
                  }}
                </b-field>
                <availiblity-chart
                  :id="option.label"
                  :minmax="option.synthesis.minmax"
                  :ranges="option.synthesis.ranges"
                  :show-dates="false"
                  class="tile availiblity-chart"
                />
              </span>
            </div>
            <div v-else-if="lineCount">
              <span :class="lineCount ? 'file-name' : 'file-name has-text-danger'">
                {{ $tc("validation.count-line", lineCount, { count: lineCount }) }}
              </span>
            </div>
            <div v-else-if="refFile">
              <span class="file-name">
                {{ refFile.name }}
              </span>
            </div>
          </slot>
        </div>
      </div>
      <div class="CollapsibleTree-buttons column is-narrow">
        <slot
          name="upload"
          v-bind:onUploadCb="onUploadCb"
          v-bind:option="option"
          v-bind:refFile="refFile"
          v-bind:repositoryRedirect="repositoryRedirect"
        >
          <div
            v-if="onUploadCb"
            :class="'file button is-small' + (option.canUpload ? ' is-info' : 'is-light')"
            :disabled="!option.canUpload"
          >
            <b-upload
              v-model="refFile"
              :disabled="!option.canUpload"
              accept=".csv"
              class="file-label ml-1"
              style="padding: 0"
              @input="() => onUploadCb(option.label, refFile) && showChildren()"
            >
              <span :disabled="!option.canUpload" class="file-cta">
                <LoadingAnimate v-if="isUploading && refFile" :size="'is-small'"></LoadingAnimate>
                <b-icon v-else icon="upload"></b-icon>
              </span>
            </b-upload>
          </div>
          <div v-else>
            <b-button
              :disabled="!(option.canUpload || option.canPublish || option.canDelete)"
              :type="
                option.canUpload || option.canPublish || option.canDelete ? 'is-info' : 'is-light'
              "
              class="ml-1"
              size="is-small"
              @click="repositoryRedirect(option.label)"
            >
              <span
                :disabled="!(option.canUpload || option.canPublish || option.canDelete)"
                class="file-cta"
                style="border-color: transparent; background-color: transparent"
              >
                <b-icon class="file-icon" icon="archive" style="color: rgb(255, 255, 255)"></b-icon>
              </span>
            </b-button>
          </div>
        </slot>
        <slot name="buttons" v-bind:buttons="buttons" v-bind:option="option">
          <div v-for="button in buttons" :key="button.id">
            <b-button
              :disabled="button.disabled"
              :icon-left="button.iconName"
              :type="button.type"
              class="ml-1"
              size="is-small"
              @click="button.clickCb(option.label)"
            >
              {{ button.label }}
            </b-button>
          </div>
        </slot>
      </div>
    </div>
    <slot v-bind:displayChildren="displayChildren" v-bind:option="option">
      <CollapsibleTree
        v-for="child in option.children"
        :key="child.id"
        :application-title="applicationTitle"
        :buttons="buildButton ? buildButton(child) : buttons"
        :build-button="buildButton"
        :class="displayChildren ? '' : 'hide'"
        :level="level + 1"
        :line-count="lineCountSynthesis(referenceSynthesis, child)"
        :on-click-label-cb="onClickLabelCb"
        :on-upload-cb="onUploadCb"
        :option="child"
        :radio-name="radioName"
        :reference-synthesis="referenceSynthesis"
        :with-radios="withRadios"
        @optionChecked="onInnerOptionChecked"
      />
    </slot>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AvailiblityChart from "../charts/AvailiblityChart.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { lineCountSynthesis } from "@/composable/application/synthesis";
import { watch } from "vue";
import useBoolean from "@/composable/components/boolean";
import TagsInfos from "@/components/common/TagsInfos.vue";
import { length } from "vee-validate/dist/rules";

export default {
  name: "CollapsibleTree",
  computed: {
    length() {
      return length;
    },
  },
  components: { TagsInfos, LoadingAnimate, FontAwesomeIcon, AvailiblityChart },
  props: {
    buildButton: Function,
    applicationName: String,
    canUpload: {
      type: Boolean,
      default: true,
    },
    option: Object,
    level: {
      type: Number,
      default: 0,
    },
    showEmpty: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    tags: {
      type: Object,
    },
    referenceSynthesis: Array,
    onClickLabelCb: Function,
    onClickLabelSynthesisDetailCb: Function,
    onUploadCb: Function,
    buttons: Array,
    withRadios: {
      type: Boolean,
      default: false,
    },
    radioName: Object,
    repository: Object,
    repositoryRedirect: Function,
    lineCount: {
      type: Number,
      default: 0,
    },
    isLoading: Boolean,
    isUploading: Boolean,
    applicationTitle: {
      type: String,
    },
  },
  setup() {
    let innerOptionChecked = null;
    let refFile = null;
    const { refBoolean: displayChildren, doChangeBoolean: changeDisplayChildren } = useBoolean();
    watch(
      () => innerOptionChecked,
      (value) => {
        this.$emit("optionChecked", value);
      }
    );

    function onInnerOptionChecked(value) {
      this.$emit("optionChecked", value);
    }

    function showChildren() {
      changeDisplayChildren(true);
    }

    return {
      onInnerOptionChecked,
      showChildren,
      lineCountSynthesis,
      displayChildren,
      refFile,
      innerOptionChecked,
    };
  },
};
</script>

<style lang="scss" scoped>
$row-height: 40px;
.synthesisDetails {
  margin-left: 10px;
}

.availiblity-chart canvas {
  width: 900px;
}

.synthesis-infos {
  width: 900px;
  display: flex;
  align-items: center;
  padding: 0.75rem;
}

.synthesis-details {
  width: auto;
  display: flex;
  align-items: center;
  padding: 0.75rem;
}

.CollapsibleTree-header.clickable {
  border-bottom: 0.35rem solid white;
}

.CollapsibleTree-header {
  display: flex;
  align-items: center;
  height: $row-height;
  padding: 0.75rem;
  justify-content: space-between;

  .file-icon {
    margin-right: 0;
  }

  .file-name {
    border: none;
    height: 100%;
    //border-left: solid 2px;
    border-radius: 0;
    padding-left: 0.5em;
    margin-left: 0.5em;
    background-color: transparent;
    color: $primary;

    &:hover {
      opacity: 1;
    }
  }
}

.CollapsibleTree-header-infos {
  display: flex;
  align-items: center;
  width: 100%;
}

.CollapsibleTree-buttons {
  display: flex;
  height: $row-height;
  align-items: center;
}

.file {
  margin-bottom: 0;
}

.file-cta {
  font-size: 1rem;
  height: 100%;
  border-color: transparent;
}

.nodata {
  margin-left: auto;
  margin-right: 50px;
}
</style>
