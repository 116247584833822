<template>
  <PageView>
    <b-tabs
      v-if="ownApplications.length + otherApplications.length"
      v-model="showOwnApplications"
      position="is-centered"
    >
      <b-tab-item
        :disabled="ownApplications.length === 0"
        :label="$t('titles.applications-page')"
      />
      <b-tab-item
        :disabled="otherApplications.length === 0"
        :label="$t('titles.applications-page-other')"
      />
    </b-tabs>

    <h1 class="title main-title">
      {{
        showOwnApplications ? $t("titles.applications-page-other") : $t("titles.applications-page")
      }}
    </h1>

    <div class="columns columnPrincipale">
      <div class="column is-3-widescreen is-12-desktop">
        <section>
          <div v-if="canCreateApplication" class="card is-clickable">
            <div
              class="card-header createApplication"
              role="button"
              style="margin-bottom: 50px"
              tabindex="0"
              @click="createApplication((applications || []).map((a) => a.name))"
            >
              <a class="card-header-icon createApplication">
                <b-icon icon="plus"></b-icon>
              </a>
              <p class="card-header-title createApplication">
                {{ $t("applications.create") }}
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">
                {{ $t("applications.trier") }}
              </p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field class="columns">
                  <b-radio
                    v-model="alphabeticalOrDateSort"
                    name="DateSort"
                    native-value="0_9"
                    @input="sortedApplicationsListForDate(applications, $event)"
                  >
                    {{ $t("applications.trierRecent") }}
                  </b-radio>
                  <b-radio
                    v-model="alphabeticalOrDateSort"
                    name="DateSort"
                    native-value="9_0"
                    @input="sortedApplicationsListForDate(applications, $event)"
                  >
                    {{ $t("applications.trierAncien") }}
                  </b-radio>
                </b-field>
              </div>
              <div class="content">
                <b-field class="columns">
                  <b-radio
                    v-model="alphabeticalOrDateSort"
                    name="alphabeticalOrDateSort"
                    native-value="Az"
                    @input="sortedApplicationsListForName(applications, $event)"
                  >
                    {{ $t("applications.trierA_z") }}
                  </b-radio>
                  <b-radio
                    v-model="alphabeticalOrDateSort"
                    name="alphabeticalOrDateSort"
                    native-value="Za"
                    @input="sortedApplicationsListForName(applications, $event)"
                  >
                    {{ $t("applications.trierZ_a") }}
                  </b-radio>
                </b-field>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">{{ $t("applications.filter") }}</p>
            </div>
            <div class="card-content">
              <div class="content">
                <b-field>
                  {{ $t("applications.name") }}
                  <b-autocomplete
                    v-model="filterName"
                    :data="selectedApplications"
                    field="localName"
                    placeholder="olac"
                  >
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="column is-9-widescreen is-12-desktop">
        <b-progress
          v-if="progress < 1"
          :value="progress * 100"
          show-value
          size="is-medium"
          type="is-primary"
        >
          <span v-if="progress === 0">
            <LoadingAnimate :size="'is-small'"></LoadingAnimate>
          </span>
          <span v-else> {{ progress.toFixed(2) * 100 }}% </span>
        </b-progress>
        <div class="columns">
          <ApplicationCard
            v-for="(application, index) in selectedApplications"
            :application-roles="
              application?.currentApplicationUserRolesResult?.applicationRoles || []
            "
            :key="application.name"
            :application="application"
            :current="current"
            :index="index"
            :per-page="perPage"
          />
        </div>
        <hr />
        <b-pagination
          v-if="perPage <= applications.length"
          :aria-current-label="$t('menu.aria-curent-page')"
          :aria-label="$t('menu.aria-pagination')"
          :aria-next-label="$t('menu.aria-next-page')"
          :aria-previous-label="$t('menu.aria-previous-page')"
          :current.sync="current"
          :per-page="perPage"
          :range-after="2"
          :range-before="2"
          :rounded="true"
          :total="applications.length"
          order="is-centered"
          role="navigation"
        >
        </b-pagination>
      </div>
    </div>
  </PageView>
</template>

<script>
import PageView from "@/views/common/PageView.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import services from "@/composable/services";
import useBoolean from "@/composable/components/boolean";
import useText from "@/composable/components/text";
import { computed, onMounted, inject } from "vue";
import { useRedirections } from "@/composable/applications/useFunction";
import ApplicationCard from "@/components/application/ApplicationCard.vue";
import useNumber from "@/composable/components/number";
import { Application } from "@/model/application/Application";

export default {
  name: "ApplicationsView",
  computed: {
    Application() {
      return Application;
    },
  },
  components: { ApplicationCard, LoadingAnimate, PageView },
  setup() {
    const { createApplication, updateApplication, displayAdditionalFilesManagement } =
      useRedirections();
    const applications = inject("application:applications");
    const ownApplications = inject("application:ownApplications");
    const showOwnApplications = inject("application:showOwnApplications");
    const hasOwnApplications = inject("application:hasOwnApplications");
    const otherApplications = inject("application:otherApplications");
    const progress = inject("application:progress");
    const loadApplications = inject("application:loadApplications");
    const checkboxDate = useBoolean().refBoolean;
    const currentUser = computed(() => services.loginService.getAuthenticatedUser());
    const canManageCreateApplication =
      currentUser.value.authorizedForApplicationCreation || currentUser.value.openAdomAdmin;
    const canCreateApplication =
      currentUser.value.authorizedForApplicationCreation && currentUser.value.authorizations.length;
    const loading = computed(() => progress.value < 1);
    const isCardModalActive = useBoolean().refBoolean;
    // show modal and cards
    const selectedName = useText().refText;
    // pagination variable
    const { refNumber: current } = useNumber(1);
    const perPage = 9;
    // filtre variable
    const filterName = useText().refText;
    const selected = useBoolean().refBoolean;
    const alphabeticalOrDateSort = useText().refText;
    const selectedApplications = computed(() => {
      let applicationsListOrderOrFilter =
        showOwnApplications.value === 0 ? ownApplications?.value : otherApplications?.value;
      if (filterName.value.length > 2)
        applicationsListOrderOrFilter = applicationsListOrderOrFilter.filter(
          (a) => a.localName.toString().toLowerCase().indexOf(filterName.value.toLowerCase()) >= 0
        );
      return applicationsListOrderOrFilter;
    });

    onMounted(async () => {
      loadApplications(["DATATYPE", "REFERENCETYPE", "CONFIGURATION", "ADDITIONALFILE"]);
    });

    function sortedApplicationsListForDate(localApplications, event) {
      localApplications =
        showOwnApplications.value === 0 ? ownApplications?.value : otherApplications?.value;
      localApplications.sort((a, b) => {
        const localA = new Date(
          a.updateDate[0],
          a.updateDate[1] - 1,
          a.updateDate[2],
          a.updateDate[3],
          a.updateDate[4],
          a.updateDate[5],
          a.updateDate[6] / 1000000
        );
        const localB = new Date(
          b.updateDate[0],
          b.updateDate[1] - 1,
          b.updateDate[2],
          b.updateDate[3],
          b.updateDate[4],
          b.updateDate[5],
          b.updateDate[6] / 1000000
        );
        return event === "0_9"
          ? localB.getTime() - localA.getTime()
          : localA.getTime() - localB.getTime();
      });
      return localApplications;
    }

    function sortedApplicationsListForName(localApplications, event) {
      localApplications =
        showOwnApplications.value === 0 ? ownApplications?.value : otherApplications?.value;
      localApplications;
      if (event === "Az") {
        localApplications = localApplications.sort((a, b) =>
          a.localName.localeCompare(b.localName)
        );
      } else if (event === "Za") {
        localApplications = localApplications
          .sort((a, b) => a.localName.localeCompare(b.localName))
          .reverse();
      }
      return localApplications;
    }

    return {
      ownApplications,
      hasOwnApplications,
      otherApplications,
      showOwnApplications,
      applications,
      progress,
      loadApplications,
      createApplication,
      updateApplication,
      canManageCreateApplication,
      displayAdditionalFilesManagement,
      current,
      perPage,
      filterName,
      selected,
      selectedApplications,
      currentUser,
      loading,
      selectedName,
      checkboxDate,
      alphabeticalOrDateSort,
      isCardModalActive,
      canCreateApplication,
      sortedApplicationsListForDate,
      sortedApplicationsListForName,
    };
  },
};
</script>

<style lang="scss" scoped>
// card & modal style
.columns {
  flex-wrap: wrap;
  margin: 0;

  &.columnPrincipale {
    margin-left: 50px;
    margin-top: 50px;
  }
}

.column {
  display: grid;

  .comment {
    display: flex;
    align-items: center;
    align-content: start;
  }

  .card {
    &.applicationCard {
      width: 300px;

      .card-footer {
        border: none;

        .card-footer-item {
          padding-right: 0;

          .button {
            padding-right: 10px;
            padding-left: 10px;
          }
        }
      }
    }

    .btnModal {
      margin: 5px;
      opacity: 50%;
      color: $primary;
      background-color: transparent;
    }

    .card-footer-item {
      border-right: none;
      padding: 0.5rem;
    }
  }
}

.createApplication {
  background-color: $dark;
  color: white;
}

.card-header-title {
  &.title {
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 5px;
  left: 5px;
}

div.PageView-container {
  width: max-content;
}
</style>
