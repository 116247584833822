<template>
  <b-collapse :open="open" animation="slide" class="card" style="margin: 0.625rem 0 0.625rem 0">
    <template #trigger="props">
      <div class="card-header" role="button">
        <p class="card-header-title" style="text-transform: capitalize">
          {{ $t("applications.advancedFilter") }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div
      id="filtreScroll"
      class="card-content"
      style="padding: 0.625em 0 0.625em 0; overflow-x: auto; overflow-y: hidden; height: 19em"
    >
      <div class="content columns" style="margin-bottom: 0.625em">
        <div
          v-for="(columns, key) in columnsToBeShown"
          :key="key"
          class="column"
          style="width: max-content; display: contents"
        >
          <b-field
            v-if="
              columns.checker?.type === 'ReferenceChecker' &&
              (isRefLinkTo[columns.id] || columns.id !== '#')
            "
            @click.native="getListeReferenceValues(columns.id, columns.refLinkedTo)"
          >
            <template #label>
              <label class="inputStyle">{{ columns.getHeader(application, dataId) }}</label>
            </template>
            <b-taginput
              :id="columns.componentKey"
              v-model="autocompleted[columns.componentKey]"
              :data="searchValueReference"
              :placeholder="$t('dataTypeAuthorizations.search')"
              :value="autocompleted[columns.componentKey]"
              autocomplete
              icon="search"
              icon-right="angle-down"
              open-on-focus
              type="search"
              @select="
                (option) =>
                  updateValue(
                    columns.id,
                    {
                      componentKey: columns.parentComponentKey
                        ? columns.parentComponentKey + '::' + columns.id
                        : columns.id,
                      filters: [],
                      columns: columns,
                    },
                    option
                  )
              "
              @typing="getNewListReferenceValuesWhenFiltered"
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="columns.componentKey + '-_-' + tag"
                  :key="index"
                  :tabstop="false"
                  aria-close-label="delete tag in input for update filter"
                  closable
                  close-type="is-danger"
                  ellipsis
                  rounded
                  type="is-primary"
                  @close="removeTag(tag, columns.componentKey)"
                >
                  {{ getDisplayValueTagForNaturalKey(tag, columns.componentKey) }}
                </b-tag>
              </template>
            </b-taginput>
          </b-field>
          <b-field
            v-else-if="
              columns.id !== '#' &&
              columns.checker !== null &&
              (columns.checker?.type === 'FloatChecker' ||
                columns.checker?.type === 'IntegerChecker' ||
                columns.checker?.type === 'DateChecker')
            "
          >
            <template #label>
              <label class="inputStyle">{{ columns.getHeader(application, dataId) }}</label>
            </template>
            <b-field>
              <FilterNumberOrDate
                :is-interval="columns.isInterval"
                :model-value="columns"
                :filters="filters"
                @sizeHeight="changeSizeHeight(columns.isInterval, $event)"
                @update:modelValue="updateValue(columns.id, $event, $event.isInterval)"
                @clear:modelValue="clear()"
              ></FilterNumberOrDate>
            </b-field>
          </b-field>
          <b-field v-else-if="columns.id !== '#'">
            <template #label>
              <label class="titleInput" style="padding: 0 0.625em 0 0.625rem">{{
                columns.getHeader(application, dataId)
              }}</label>
            </template>
            <b-taginput
              :id="columns.componentKey"
              v-model="filters[columns.componentKey]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              :value="filters[columns.componentKey]"
              class="is-primary inputStyle"
              icon="search"
              type="search"
              @blur="
                updateValue(
                  columns.id,
                  {
                    componentKey: columns.id,
                    filters: filters[columns.componentKey],
                    columns: columns,
                  },
                  null
                )
              "
              @keyup.native.enter="
                updateValue(
                  columns.id,
                  {
                    componentKey: columns.id,
                    filters: filters[columns.componentKey],
                    columns: columns,
                  },
                  null
                )
              "
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="columns.componentKey + '-_-' + tag"
                  :key="index"
                  closable
                  close-type="is-danger"
                  rounded
                  type="is-primary"
                  @close="removeTag(tag, columns.componentKey)"
                >
                  {{ tag }}
                </b-tag>
              </template>
            </b-taginput>
          </b-field>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-footer-item">
        <b-button
          expanded
          icon-left="redo"
          outlined
          type="is-danger"
          @click="clear()"
          disabled="!filters.length"
          >{{ $t("dataTypesManagement.réinitialiser") }}
          {{ $t("dataTypesManagement.filtre") }}
        </b-button>
      </div>
      <!--
      <div class="card-footer-item">
        <b-button
          expanded
          icon-left="eye"
          outlined
          type="is-dark"
          @click="$emit('view-search', { filters })"
        >
          {{ $t("dataTable.consult-result-filter") }}
        </b-button>
      </div>-->
      <div class="card-footer-item">
        <b-button
          expanded
          icon-left="download"
          type="is-primary"
          @click="$emit('download-search', { filters })"
        >
          {{
            filters.length
              ? $t("dataTable.donwload-result-filter")
              : $t("dataTable.donwload-result")
          }}
        </b-button>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import useArray from "@/composable/components/array";
import useBoolean from "@/composable/components/boolean";
import services from "@/composable/services";
import { dataLoader } from "@/composable/data/dataLoader";
import { provide, ref } from "vue";
import FilterNumberOrDate from "@/components/common/provider/FilterNumberOrDate.vue";

export default {
  components: { FilterNumberOrDate },
  emits: ["view-search", "clear-search", "download-search"],
  name: "FiltersDataCollapse",
  props: {
    application: {
      type: Object,
    },
    applicationName: {
      type: String,
    },
    dataId: {
      type: String,
    },
    isRefLinkTo: {
      type: Object,
      defaults: [],
    },
    columnsToBeShown: {
      type: Array,
      defaults: [],
    },
    columns: {
      type: Array,
      defaults: [],
    },
  },
  setup(props) {
    const { shallowRefArray: filters, doChangeArray: changeFilters } = useArray();
    const { shallowRefArray: autocompleted, doChangeArray: changeAutocompleted } = useArray();
    const { shallowRefArray: listReferenceValueForAllReferences } = useArray();
    const { refBoolean: open } = useBoolean(true);
    const { shallowRefArray: searchValueReference, doChangeArray: changeSearchValueReference } =
      useArray();
    const {
      shallowRefArray: originalListValuesForReferance,
      doChangeArray: changeOriginalListValuesForReferance,
    } = useArray();
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const { getValueDisplay } = loader;
    const reference = ref();

    function getNewListReferenceValuesWhenFiltered(text) {
      changeSearchValueReference(
        originalListValuesForReferance.value.filter((option) => {
          return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
        })
      );
    }

    async function getListeReferenceValues(columnsId, refLinkedTo) {
      let valueReference = [];
      if (columnsId || refLinkedTo) {
        changeSearchValueReference([]);
        if (props.isRefLinkTo[columnsId] !== undefined) {
          reference.value = await services.dataService.getData(
            props.applicationName,
            props.isRefLinkTo[columnsId]
          );
        } else if (refLinkedTo !== undefined) {
          reference.value = await services.dataService.getData(props.applicationName, refLinkedTo);
        }
        if (reference.value && reference.value.rows) {
          for (let i = 0; i < reference.value.rows.length; i++) {
            if (reference.value.rows[i]) {
              valueReference.push(getValueDisplay(reference.value.rows[i], props.application));
            }
          }
        }
      }
      changeSearchValueReference(valueReference);
      changeOriginalListValuesForReferance(valueReference);
      listReferenceValueForAllReferences.value[columnsId] = reference.value;
    }

    function updateValue(columnsId, event, option) {
      event.filters = filters.value[columnsId] || autocompleted.value[columnsId];
      let localFilter = [];
      localFilter = filters.value;
      localFilter = localFilter.filter(
        (filter) => (event.componentKey || columnsId) !== filter?.componentKey
      );
      if (event.isInterval) {
        event = {
          componentKey: event.componentKey,
          intervalsValues: [{ from: event.intervalValues.from, to: event.intervalValues.to }],
        };
        localFilter.push(event);
        changeFilters(localFilter);
      } else if (event.simpleValue !== undefined) {
        event.filters = event.filters || [];
        event.filters[0] = event.simpleValue;
        localFilter.push(event);
        changeFilters(localFilter);
      }
      if (event.filters) {
        if (option !== null && option.key !== "Enter") {
          if (event.isInterval) {
            event = {
              componentKey: event.componentKey,
              intervalsValues: [{ from: event.intervalValues.from, to: event.intervalValues.to }],
            };
          } else {
            if (event.simpleValue !== undefined) {
              event.filters = event.filters || [];
              event.filters[0] = event.simpleValue;
            } else if (typeof option !== "object") {
              event.filters = checkedValueIsInListFilter(option, columnsId, event);
              autocompleted.value = checkedValueIsInListFilter(
                option,
                columnsId,
                null,
                autocompleted.value
              );
              autocompleted.value[columnsId] = [...new Set(autocompleted.value[columnsId])];
            } else {
              event.filters = checkedValueIsInListFilter(option, columnsId, event);
              filters.value = checkedValueIsInListFilter(option, columnsId, null, filters.value);
              filters.value[columnsId] = [...new Set(filters.value[columnsId])];
            }
            if (event.filters && event.filters.length !== 0) {
              for (let i = 0; i < event.filters.length; i++) {
                if (getNaturalKey(event.filters[i], columnsId)) {
                  event.filters[i] = getNaturalKey(event.filters[i], columnsId);
                }
              }
            }
            event = { componentKey: event.componentKey, filters: [...new Set(event.filters)] };
          }
          localFilter.push(event);
          changeFilters(localFilter);
        } else if (option === null) {
          localFilter.push(event);
          changeFilters(localFilter);
        }
      }
      if (event.filters && event.filters.length !== 0) {
        for (let i = 0; i < event.filters.length; i++) {
          if (getNaturalKey(event.filters[i], columnsId)) {
            event.filters[i] = getNaturalKey(event.filters[i], columnsId);
          }
        }
        event = { componentKey: event.componentKey, filters: [...new Set(event.filters)] };
      }
    }

    function removeTag(tagValue, componentKey) {
      let index = 0;
      let listNaturalKeyValue = [];
      let localFilter = filters.value;
      localFilter = localFilter.filter((filter) => componentKey !== filter?.componentKey);
      if (tagValue && componentKey) {
        if (autocompleted.value[componentKey] && autocompleted.value[componentKey].length !== 0) {
          index = autocompleted.value[componentKey].indexOf(tagValue);
          if (index > -1) {
            autocompleted.value[componentKey].splice(index, 1); // 2nd parameter means remove one item only
          }
          if (autocompleted.value[componentKey] && autocompleted.value[componentKey] !== 0) {
            for (let i = 0; i < autocompleted.value[componentKey].length; i++) {
              if (getNaturalKey(autocompleted.value[componentKey][i], componentKey)) {
                listNaturalKeyValue[i] = getNaturalKey(
                  autocompleted.value[componentKey][i],
                  componentKey
                );
              }
            }
          }
          autocompleted.value[componentKey] = [...new Set(autocompleted.value[componentKey])];
          localFilter.push({
            componentKey: componentKey,
            filters: listNaturalKeyValue,
          });
          changeAutocompleted(autocompleted.value);
          changeFilters(localFilter);
        }
        if (
          (filters.value && filters.value.length !== 0) ||
          Object.keys(filters.value).length !== 0
        ) {
          if (filters.value[componentKey] && filters.value[componentKey].length !== 0) {
            index = filters.value[componentKey].indexOf(tagValue);
            if (index > -1) {
              filters.value[componentKey].splice(index, 1); // 2nd parameter means remove one item only
            }
          }
          for (let i = 0; i < filters.value.length; i++) {
            if (
              filters.value[i] &&
              filters.value[i].componentKey === componentKey &&
              filters.value[i].filters.length !== 0
            ) {
              index = filters.value[i].filters.indexOf(tagValue);
              if (index > -1) {
                filters.value[i].filters.splice(index, 1); // 2nd parameter means remove one item only
                filters.value[i].filters = [...new Set(filters.value[i].filters)];
              }
            } else if (filters.value[i] && filters.value[i].filters.length === 0) {
              delete filters.value[i];
            }
          }
          if ((filters.value.length === 1 && !filters.value[0]) || filters.value.length === 0) {
            filters.value = [];
          } else {
            filters.value = [...new Set(filters.value)];
          }
          changeFilters(filters.value);
        }
      }
    }

    function checkedValueIsInListFilter(option, columnsId, event, objectFilterOrAutocomplted) {
      if (event !== null && !event.filters.includes(option)) {
        return event.filters.push(option);
      } else if (event !== null && event.filters.includes(option)) {
        return event.filters;
      }
      if (
        objectFilterOrAutocomplted &&
        (Object.keys(objectFilterOrAutocomplted).length === 0 ||
          (Object.keys(objectFilterOrAutocomplted).includes(columnsId) &&
            !objectFilterOrAutocomplted[columnsId].includes(option)))
      ) {
        objectFilterOrAutocomplted[columnsId].push(option);
      }
      return objectFilterOrAutocomplted;
    }

    function changeSizeHeight(collapside, event) {
      let height = document.getElementById("filtreScroll").style.height;
      height = height.substring(0, height.length - 2);
      if (event) {
        document.getElementById("filtreScroll").style.height =
          parseInt(height) + parseInt(event.value) + "rem";
      } else if (collapside) {
        document.getElementById("filtreScroll").style.height = parseInt(height) * 2 + "rem";
      } else {
        document.getElementById("filtreScroll").style.height = 19 + "em";
      }
    }

    function clear() {
      let listInput = document.getElementsByClassName("tag");
      for (let i = 0; i < listInput.length; i++) {
        if (listInput[i].querySelector(".delete")) {
          listInput[i].querySelector(".delete").click();
        }
        listInput[i].value = "";
      }
      listInput = document.getElementsByClassName("input");
      for (let i = 0; i < listInput.length; i++) {
        listInput[i].value = "";
      }
      changeFilters([]);
      changeAutocompleted([]);
      return this.$emit("clear-search", { filters });
    }

    function getDisplayValueTagForNaturalKey(value, columnsId) {
      if (
        listReferenceValueForAllReferences.value[columnsId] &&
        listReferenceValueForAllReferences.value[columnsId].rows
      ) {
        for (let i = 0; i < listReferenceValueForAllReferences.value[columnsId].rows.length; i++) {
          if (listReferenceValueForAllReferences.value[columnsId].rows[i].naturalKey === value) {
            if (
              listReferenceValueForAllReferences.value[columnsId].rows[i].values[
                "__display_default"
              ]
            ) {
              return listReferenceValueForAllReferences.value[columnsId].rows[i].values[
                "__display_default"
              ];
            } else if (
              listReferenceValueForAllReferences.value[columnsId].rows[i].values[
                "__display_" + services.internationalisationService.getLocale()
              ]
            ) {
              return listReferenceValueForAllReferences.value[columnsId].rows[i].values[
                "__display_" + services.internationalisationService.getLocale()
              ];
            } else {
              return listReferenceValueForAllReferences.value[columnsId].rows[i].naturalKey;
            }
          }
        }
      }
      return value;
    }

    function getNaturalKey(value, columnsId) {
      if (
        listReferenceValueForAllReferences.value[columnsId] &&
        listReferenceValueForAllReferences.value[columnsId].rows
      ) {
        for (let i = 0; i < listReferenceValueForAllReferences.value[columnsId].rows.length; i++) {
          if (
            listReferenceValueForAllReferences.value[columnsId].rows[i].values[
              "__display_default"
            ] === value ||
            listReferenceValueForAllReferences.value[columnsId].rows[i].values[
              "__display_" + services.internationalisationService.getLocale()
            ] === value
          ) {
            return listReferenceValueForAllReferences.value[columnsId].rows[i].naturalKey;
          }
        }
      }
    }

    return {
      changeSizeHeight,
      clear,
      getListeReferenceValues,
      updateValue,
      filters,
      autocompleted,
      open,
      searchValueReference,
      getNewListReferenceValuesWhenFiltered,
      getValueDisplay,
      removeTag,
      getDisplayValueTagForNaturalKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.inputStyle {
  padding: 0 0.625em 0 0.625rem;
}
</style>
