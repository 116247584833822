export class User {
  static states = { idle: false, active: true, pending: false, closed: false };
  static INSTANCE = new User();
  static STORED_AUTHENTICATED_USER = function () {
    let authenticatedUser = localStorage.getItem("authenticatedUser");
    return authenticatedUser ? JSON.parse(authenticatedUser) : {};
  };

  id = "";
  login = "";
  email = "";
  state = Object.keys(User.states)[0];
  authorizedForApplicationCreation = false;
  openAdomAdmin = false;
  authorizations = [];
  chartes = {};
  currentUserRoles = {
    applicationRoles: {},
    userId: "",
    userLogin: "",
    isOpenAdomAdmin: false,
    isApplicationCreator: false,
    memberOf: [],
    isDataBaseSuper: false,
  };
  lang = navigator.language.slice(0, 2) || "fr";

  constructor(userData) {
    if (!userData) {
      let user = localStorage.getItem("authenticatedUser");
      user = user ? JSON.parse(user) : {};
      if (!user) {
        return;
      }
      this.id = user.id;
      this.login = user.login;
      this.email = user.email;
      this.state = User.states[this.state] ? this.state : Object.keys(User.states)[0];
      this.authorizedForApplicationCreation = user.authorizedForApplicationCreation;
      this.openAdomAdmin = user.openAdomAdmin;
      this.authorizations = user.authorizations;
      this.chartes = user.chartes;
      this.currentUserRoles = user.currentUserRoles;
      return;
    }
    Object.assign(this, userData);
    this.state = User.states[this.state] ? this.state : Object.keys(User.states)[0];
  }

  invalidateCharte(applicationId) {
    if (!this.chartes[applicationId]) {
      this.chartes[applicationId] = false;
    }
  }

  reset() {
    User.INSTANCE = new User();
  }

  change(userData) {
    User.INSTANCE = new User(userData);
  }

  isCharteSigned(application) {
    return (
      (application && this.isApplicationCreator(application.name)) ||
      (this.chartes[application.id] && this.chartes[application.id] < new Date().getTime())
    );
  }

  isApplicationCreator(applicationName) {
    return this.currentUserRoles?.isApplicationCreator || this.hasAuthorization(applicationName);
  }

  isApplicationManager(applicationId) {
    return this.hasRole(applicationId, "applicationManager");
  }

  isUserManager(applicationId) {
    return this.hasRole(applicationId, "userManager");
  }

  isReader(applicationId) {
    return this.hasRole(applicationId, "reader");
  }

  isWriter(applicationId) {
    return this.hasRole(applicationId, "writer");
  }

  hasRole(applicationId, role) {
    const roles = this.currentUserRoles?.applicationRoles[applicationId];
    return roles && roles.includes(role);
  }

  hasAuthorization(pattern) {
    return this.authorizations.some((auth) => new RegExp(auth).test(pattern));
  }

  getMemberOf() {
    return this.currentUserRoles?.memberOf;
  }
}
