<template>
  <div>
    <!-- section pour visualisation un lien de référence -->
    <a v-if="referenceType" class="button inTable" @click="openReferenceDetail()">
      {{ displayValue ? displayValue : value }}
      <LoadingAnimate v-if="isLoading" :size="'is-small'"></LoadingAnimate>
    </a>
    <p v-else-if="patternCheckerDate">{{ datePatternLang(value, patternCheckerDate) }}</p>
    <p v-else-if="typeof value === 'string' && value.indexOf('date') === 0">
      {{ datePatternLang(value) }}
    </p>
    <p v-else class="column">{{ value }}</p>

    <!-- modal de visualisation d'une donnée de référence -->
    <b-modal v-model="isCardModalActive" custom-class="referenceDetails">
      <div class="card">
        <header class="card-header is-align-content-center">
          <p
            class="card-header-title"
            field="name"
            style="font-size: 1.5rem; color: rgb(0, 127, 127)"
          >
            {{
              reference
                ? reference.localName !== value
                  ? getValueDisplay(reference.rows[0], application)
                  : displayValue
                : value
            }}
          </p>
        </header>
        <div class="card-content">
          <div class="content is-align-content-center">
            <b-table :data="refValuesObj.data">
              <b-table-column
                v-for="column in refValuesObj.columns"
                :key="column.id"
                v-slot="props"
                :label="column.label"
              >
                <span>
                  <div v-if="typeof props.row[column.field] == 'boolean'">
                    {{ props.row[column.field] ? $t("boolean.true") : $t("boolean.false") }}
                  </div>
                  <DatasDynamicLink
                    v-else-if="
                      !props.row[column.field]?.length && props.row[column.field].length !== 0
                    "
                    :application="application"
                    :column-id="column.id"
                    :info="!props.row[column.field]?.length && props.row[column.field].length !== 0"
                    :info-values="props.row[column.field]"
                    :loaded-references-by-key="{}"
                    :reference-type="dynamicColumnReferences(props.row.colonne)"
                  ></DatasDynamicLink>
                  <DatasManyLink
                    v-else-if="
                      Array.isArray(props.row[column.field]) && props.row[column.field].length !== 0
                    "
                    :application="application"
                    :column-id="column.id"
                    :info-values="props.row[column.field]"
                    :loaded-references-by-key="{}"
                    :multiplicity="true"
                    :reference-type="searchRefLinkedTo(props.row['colonne'])"
                  ></DatasManyLink>
                  <DatasLink
                    v-else-if="
                      column.field === 'valeur' &&
                      refValuesObj.referenceTypeForReferencingColumns[props.row.colonne]
                    "
                    :application="application"
                    :loaded-references-by-key="{}"
                    :display-value="
                      reference.values.displaysForRow[
                        refValuesObj.referenceTypeForReferencingColumns[props.row.colonne]
                      ][props.row.valeur]
                    "
                    :reference-type="
                      refValuesObj.referenceTypeForReferencingColumns[props.row.colonne]
                    "
                    :value="props.row.valeur"
                  ></DatasLink>
                  <p v-else-if="column.field === 'colonne'">
                    {{
                      getColumnNameView(props.row[column.field], application, referenceType, column)
                    }}
                  </p>
                  <p v-else>{{ props.row[column.field] }}</p>
                </span>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatasDynamicLink from "@/components/datas/DatasDynamicLink.vue";
import DatasManyLink from "@/components/datas/DatasManyLink.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import { inject, ref, watch } from "vue";
import useBoolean from "@/composable/components/boolean";
import useObject from "@/composable/components/object";
import { datePatternLang } from "../../composable/application/DatePattern";

export default {
  name: "DatasLink",
  emits: ["changedRefValues"],
  components: { LoadingAnimate, DatasManyLink, DatasDynamicLink },
  props: {
    patternCheckerDate: String,
    component: Object,
    application: Object,
    referenceType: String,
    value: String,
    displayValue: String,
    infoValues: {},
    loadedReferencesByKey: {
      type: Object,
    },
    row: {
      type: Object,
    },
    columnId: String,
  },
  beforeCreate() {
    this.$options.components.DatasDynamicLink = require("./DatasDynamicLink.vue").default;
    this.$options.components.DatasManyLink = require("./DatasManyLink.vue").default;
  },
  setup(props) {
    const { getValueDisplay, getOrLoadDataByNaturalKey, getColumnNameView } =
      inject("reference:dataLoader");
    const { reactiveObject: refValuesObj, doChangeObject: changeRefValuesObj } = useObject({
      active: false,
      localName: props.value,
    });
    const isCardModalActive = useBoolean().refBoolean;
    const { refBoolean: isLoading, doChangeBoolean: changeIsLoading } = useBoolean();
    const reference = ref();

    watch(() => props.value, getOrLoadReference);

    async function getOrLoadReference() {
      if (props.referenceType && (props.value || props.columnId)) {
        const result = await getOrLoadDataByNaturalKey(
          props.application,
          props.referenceType,
          props.columnId || props.value
        );
        let referenceTypeForReferencingColumns = result.referenceTypeForReferencingColumns;
        if (result.rows.length !== 0) {
          let refValue = result.rows[0].values;
          result.refValues = {
            referenceTypeForReferencingColumns,
            refValue,
          };
        }
        reference.value = await result;
        reference.value.localName = props.value;
      }
    }

    async function openReferenceDetail() {
      changeIsLoading(true);
      await getOrLoadReference();
      let refValues = reference.value.refValues;
      const data = Object.entries(refValues.refValue)
        .filter((entry) => !entry[0].startsWith("__display_"))
        .map((entry) => ({ colonne: entry[0], valeur: entry[1] }))
        .reduce((acc, entry) => {
          acc.push(entry);
          return acc;
        }, []);
      const result = {
        data: data,
        columns: [
          {
            field: "colonne",
            label: "Colonne",
          },
          {
            field: "valeur",
            label: "Valeur",
          },
        ],
        active: true,
        reference: props.referenceType,
      };
      isCardModalActive.value = true;
      let newObject = { ...refValues, ...result, localRefName: reference.localRefName };
      changeRefValuesObj(newObject);

      this.$emit("changedRefValues", {
        key: props.value,
        refType: props.referenceType,
        refValuesObj,
        refValue: refValuesObj.refValue,
      });
      changeIsLoading(false);

      return refValues;
    }

    function dynamicColumnReferences(nameId) {
      if (
        props.application.configuration.dataDescription[props.referenceType].componentDescriptions[
          nameId
        ].type === "DynamicComponent"
      ) {
        return props.application.configuration.dataDescription[props.referenceType]
          .componentDescriptions[nameId].reference;
      }
    }

    function searchRefLinkedTo(columnName) {
      if (
        props.application.configuration.dataDescription[props.referenceType].depends.length !== 0
      ) {
        return props.application.configuration.dataDescription[props.referenceType].depends[0]
          .component === columnName
          ? props.application.configuration.dataDescription[props.referenceType].depends[0]
              .references
          : "";
      }
      return "";
    }

    return {
      dynamicColumnReferences,
      openReferenceDetail,
      isCardModalActive,
      isLoading,
      refValuesObj,
      reference,
      datePatternLang,
      getOrLoadDataByNaturalKey,
      getValueDisplay,
      getColumnNameView,
      searchRefLinkedTo,
    };
  },
};
</script>

<style lang="scss" scoped>
.button.inTable {
  color: $dark;
  background-color: transparent;
  border: transparent;
}

.button.inTable:hover {
  color: $dark;
  background-color: transparent;
  border: transparent;
  text-decoration: underline;
}
</style>
