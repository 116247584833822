import { Fetcher } from "../Fetcher";
import services from "@/composable/services";

export class DataService extends Fetcher {
  static INSTANCE = new DataService();

  constructor() {
    super();
  }
  async loadChunks(method, url) {
    return this.getChunck(method, url);
  }

  async getData(applicationName, dataName, params, loadExample) {
    const locale = services.internationalisationService.getLocale();
    params = params || {};
    params.outPut = params.outPut || {};
    params.outPut.locale = locale;
    if (params) {
      return this.get(`applications/${applicationName}/data/${dataName}/json`, {
        downloadDatasetQuery: JSON.stringify(params),
        loadExample: !!loadExample,
      });
    } else {
      return this.get(`applications/${applicationName}/data/${dataName}/json`);
    }
  }

  async getListValueForReferenceInFilter(dataColumnsToBeShown, applicationName) {
    let searchValueReference = [];
    for (let component in dataColumnsToBeShown) {
      if (
        dataColumnsToBeShown[component].checker &&
        dataColumnsToBeShown[component].checker.type &&
        dataColumnsToBeShown[component].checker.type === "ReferenceChecker"
      ) {
        searchValueReference[dataColumnsToBeShown[component].componentKey] = await this.getData(
          applicationName,
          dataColumnsToBeShown[component].refLinkedTo
        );
      }
    }
    return searchValueReference;
  }

  async getDataByNaturalKey(applicationName, dataName, referenceKey) {
    let params = { naturalKeys: [referenceKey] };
    return await this.getData(applicationName, dataName, params);
  }

  async getDataZip(applicationName, dataTypeId, params) {
    return this.getPath(
      `applications/${applicationName}/data/${dataTypeId}/zip`,
      {
        downloadDatasetQuery: JSON.stringify(params),
      },
      true
    );
  }

  async getDataCsv(applicationName, dataName) {
    return this.get(`applications/${applicationName}/data/${dataName}/csv`, {}, true);
  }

  getDatasCsvPath(applicationName, dataTypeId, params) {
    return this.getPath(
      `applications/${applicationName}/data/${dataTypeId}/csv`,
      {
        downloadDatasetQuery: JSON.stringify(params),
      },
      true
    );
  }

  async addData(applicationName, dataName, dataFile, params) {
    return this.post(`applications/${applicationName}/data/${dataName}`, {
      file: dataFile,
      params: JSON.stringify(params),
    });
  }

  async deleteReferenceValuesByKey(applicationName, dataName, rowId) {
    let params = { rowIds: [rowId] };
    return this.delete(`applications/${applicationName}/data/${dataName}`, params);
  }
}
