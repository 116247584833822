import { reactive } from "vue";

function useObject(obj = {}) {
  if (!obj.toString() === "[object, object]") {
    throw new Error("An object is expected. Passing value : " + obj);
  }
  let _keys = Object.keys(obj);
  const reactiveObject = reactive(obj);
  const doChangeObject = function (newObject = {}) {
    _keys.forEach((key) => delete reactiveObject[key]);
    Object.assign(reactiveObject, newObject);
    _keys = Object.keys(newObject);
  };
  const doAddToObject = function (newObject = {}) {
    reactiveObject.value = Object.assign(reactiveObject, newObject);
  };

  return {
    reactiveObject,
    doChangeObject,
    doAddToObject,
  };
}

export default useObject;
