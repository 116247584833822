import { i18n } from "@/main";
import { BuefyTypes } from "@/utils/BuefyUtils";
import { DialogProgrammatic, ToastProgrammatic } from "buefy";

const TOAST_INFO_DURATION = 3000;
const TOAST_ERROR_DURATION = 5000;
const TOAST_POSITION = "is-top";

/**
 * Un service pour gérer les différents messages d'alerte et popup d'info s'affichant sur l'application
 */
export class AlertService {
  static INSTANCE = new AlertService();

  toastSuccess(message) {
    ToastProgrammatic.open({
      message: message,
      type: BuefyTypes.SUCCESS,
      duration: TOAST_INFO_DURATION,
      position: TOAST_POSITION,
    });
  }

  toastWarn(message, error) {
    console.warn("[WARNING] " + message, error);
    ToastProgrammatic.open({
      message: message,
      type: BuefyTypes.WARNING,
      duration: TOAST_ERROR_DURATION,
      position: TOAST_POSITION,
    });
  }

  toastError(message, error, state) {
    if (error && error.content) {
      error.content.then((t) => {
        if (typeof t === "string") {
          let regExpMatchArray = t.match("application inconnue (.*)");
          if (regExpMatchArray) {
            ToastProgrammatic.open({
              message: i18n.t("applications.no-right-for-application"),
              type: BuefyTypes.DANGER,
              duration: TOAST_ERROR_DURATION,
              position: TOAST_POSITION,
            });
            t = "NO-RIGHTS";
          } else {
            ToastProgrammatic.open({
              message: i18n.t("exceptionMessage." + t),
              type: BuefyTypes.DANGER,
              duration: TOAST_ERROR_DURATION,
              position: TOAST_POSITION,
            });
          }
        } else if ("NO_RIGHT_FOR_APPLICATION_MANAGEMENT" === t.message) {
          t = "NO-RIGHTS";
        } else {
          const parseMessage = /(^[^[]*) \[(.*)\]$/.exec(t.message);
          const message = parseMessage?.[1] || t.message;
          const params = t.params || {};
          (parseMessage?.[2].split(",") || []).forEach(
            (param, index) => (params["param" + index] = param.trim())
          );
          ToastProgrammatic.open({
            message: i18n.t("exceptionMessage." + message, params),
            type: BuefyTypes.DANGER,
            duration: TOAST_ERROR_DURATION,
            position: TOAST_POSITION,
          });
        }
        if (state) state.value = t;
      });
    } else {
      ToastProgrammatic.open({
        message: message,
        type: BuefyTypes.DANGER,
        duration: TOAST_ERROR_DURATION,
        position: TOAST_POSITION,
      });
    }
  }

  toastServerError(error, state) {
    if (error && error.content != null) {
      error.content.then((value) => this.toastError(value.message, error, state));
    } else {
      this.toastError(i18n.t("alert.server-error"), error, state);
    }
  }

  dialog(title, message, confirmText, type = "warning", cancelMsg, onConfirmCb = () => {}) {
    DialogProgrammatic.confirm({
      title: title,
      message: message,
      confirmText: confirmText,
      type: type,
      hasIcon: true,
      cancelText: cancelMsg,
      onConfirm: () => {
        onConfirmCb();
      },
    });
  }
}
